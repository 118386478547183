import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'

const TeaserText = ({fullText, teaserText, length = 200, className}) => {

    const text = (teaserText) ? teaserText : fullText.substring(0,length) + '...';

    return (
        <Markdown
          className={className}
          source={text}
          escapeHtml={false}
        />
    )
}
TeaserText.propTypes = {
  fullText: PropTypes.string.isRequired,
  length: PropTypes.number,
  teaserText: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ])
}

export default TeaserText
