import React from 'react'
import { Link } from 'gatsby'
import TeaserText from '../Node/teaserText'

export default ({children, articleData,type}) => {
    if(type === 'intro'){
        return(
            <div className="intro__blog">
                <h1 className="intro__blog-title">
                    { articleData.title }
                </h1>
                <h5
                    className="intro__blog-subtitle"
                >
                  <TeaserText
                    fullText={articleData.body.processed}
                    teaserText={articleData.body.summaryProcessed}
                    length={73}
                  />
                </h5>
                <p>
                    <Link
                        to={articleData.entityUrl.path}
                        className={"intro__blog-button button secondary"}
                    >
                        Lees verder
                    </Link>
                </p>
            </div>
        )
    }else{
        return(
            <React.Fragment>
                <article className='card-user-profile'>
                    <img
                        className="card-user-profile-img"
                        src={articleData.fieldImage.url}
                        alt={articleData.title}
                    />
                    <div className="card-user-profile-content card-section">
                        <p className="card-user-profile-name">
                            { articleData.title }
                        </p>
                        <TeaserText
                            className="card-user-profile-info"
                            fullText={articleData.body.processed}
                            teaserText={articleData.body.summaryProcessed}
                        />
                    </div>
                    <div className="card-user-profile-actions">
                        <Link
                            to={articleData.entityUrl.path}
                            className={"card-user-profile-button button hollow secondary"}
                        >
                            Lees verder
                        </Link>
                    </div>
                </article>
            </React.Fragment>
        )
    }
}
