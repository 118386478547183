import React from 'react'
import Layout from '../components/Layout/layout'
import Helmet from 'react-helmet'
import Title from '../components/Node/node_title'
import Article from '../components/Article/article'
import { sanitizeUrl } from '../helpers/'


export default (props) => {
    const taxonomy = props.pageContext.taxonomy;
    return (
        <React.Fragment>
            <Helmet>
                <title>{taxonomy.entityLabel}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <meta name="MobileOptimized" content="width"/>
                {
                    taxonomy.entityMetatags.map((metatag,key) => {
                        if(metatag.__typename === 'Default_MetaProperty'){
                            return <meta key={key} property={metatag.key} content={sanitizeUrl(metatag.value,'cms.')}/>
                        }else if(metatag.__typename === 'Default_MetaLink'){
                            return <link key={key} rel={metatag.key} href={sanitizeUrl(metatag.value,'cms.')}/>
                        }else{
                            return <meta key={key} name={metatag.key} content={sanitizeUrl(metatag.value,'cms.')}/>
                        }
                    })
                 }
            </Helmet>
            <Layout>
                <Title
                    title={taxonomy.entityLabel}
                />
                <div className="node__body">
                    <div className="grid-container">
                        <div
                            className="grid grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2 large-up-4"
                        >
                            {
                                taxonomy.reverseFieldTagsNode.entities.map((term, index) => {
                                    return <div
                                        className="cell"
                                        key={index}
                                    >
                                            <Article
                                                articleData={term}
                                            />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    )
}